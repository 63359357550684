<template>
  <a-modal
    title="新增"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" @submit="handleSubmit">
      <a-form-item label="出库通知单">
        <a-select v-decorator="['delivery_order', { rules: [{ required: true, message: '请选择出库通知单' }]}]" size="small" style="width: 100%">
          <a-select-option v-for="delivery in deliveryOrderItems" :key="delivery.id" :value="delivery.id">
            {{ delivery.number }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="收费项目">
        <a-select v-decorator="['charge_item', { rules: [{ required: true, message: '请选择收费项目' }]}]" size="small" style="width: 100%">
          <a-select-option v-for="charge in chargeItems" :key="charge.id" :value="charge.id">
            {{ charge.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="收费金额">
        <a-input v-decorator="['amount', { rules: [{ required: true, message: '请输入收费金额' }, { pattern: /^\d{0,14}(\.\d{0,2})?$/, message: '收费金额格式不正确' }]}]" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { chargeItemsOption, deliveryOrdersOption } from '@/api/option'
import { chargeRecordsOutCreate } from '@/api/charging'

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      chargeItems: [],
      deliveryOrderItems: [],
    }
  },
  methods: {
    show () {
      this.visible = true
      chargeItemsOption({ page_size: 999999 }).then(data => {
        this.chargeItems = data.results
      })
      deliveryOrdersOption({ page_size: 999999 }).then(data => {
        this.deliveryOrderItems = data.results
      })
    },
    handleOk () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          chargeRecordsOutCreate(values).then(data => {
            this.$message.success('创建成功')
            this.$emit('ok')
            this.handleCancel()
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
</script>